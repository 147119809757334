import React, {useState, useEffect, memo} from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Close from '@material-ui/icons/Close'
import Collapse from '@material-ui/core/Collapse'
import DatePicker from 'react-datepicker'
import GMap from 'components/GoogleMap/Gmap'
import SearchField from 'components/SearchField'
import TripCard from 'components/TripCard/TripCard'
import Pagination from 'components/Pagination'
import useTripStore from 'store/TripStore'
import Slider from 'ui/Slider/Slider'
import {useLocation} from 'react-router-dom'
import CircularLoading from 'ui/CircularLoading/CircularLoading'
import styles from './Dashboard.module.scss'

const basicFilters = [
  ['', 'All'],
  ['t', 'Yes'],
  ['f', 'No'],
]
const driveType = [
  ['', 'All'],
  [1, 'Commute'],
  [2, 'Adventure'],
]
const gender = [
  ['', 'All'],
  [1, 'Male'],
  [2, 'Female'],
  [3, 'Other'],
]
let markersArr = []

const eventNames = [
  'No Event',
  'Concerts',
  'Music Festivals',
  'Entertainment show',
  'Museum',
  'Cultural Event',
]

const addDays = (date, days) => {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000)
}

const MenuProps = {PaperProps: {style: {maxHeight: 300}}}

let initalLoad = false

const Dashboard = () => {
  const location = useLocation()

  const {
    searchedTrips: trips,
    waypoints,
    similarTrips,
    searchPagination: pagination,
    dataLoaded,
    resetDataLoadedRequest,
    searchTripIdsRequest,
  } = useTripStore()

  const today = new Date()
  const initial_state = {
    latitude: 39.73915,
    longitude: -104.9847,
    locationAvailable: false,
    expanded: false,
    showTrip: false,
    dataLoaded: false,
    filters: localStorage.getItem('search_filters')
      ? JSON.parse(localStorage.getItem('search_filters'))
      : location?.state?.filters
      ? JSON.parse(location?.state?.filters)
      : {
          start_price: 0,
          end_price: 250,
          event_name: [],
        },
    trip_cache: {},
    selected: {},
    finish_date1:
      today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear(),
  }

  const [state, setState] = useState(initial_state)
  const [firstLoading, setFirstLoading] = useState(
    !initalLoad || localStorage.getItem('search_refresh') === '1',
  )

  useEffect(() => {
    localStorage.setItem('search_filters', JSON.stringify(state.filters))
  }, [state.filters])

  useEffect(() => {
    const searchByFilters = async () => {
      await resetDataLoadedRequest()
      await searchTripIdsRequest(state.filters)
      setFirstLoading(false)
      initalLoad = true
    }

    if (!initalLoad || localStorage.getItem('search_refresh') === '1') {
      localStorage.setItem('search_refresh', '0')
      searchByFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setState((prveState) => ({
      ...prveState,
      dataLoaded: dataLoaded,
    }))
    if (dataLoaded) {
      var elems = document.querySelectorAll('.clicked-page')
      ;[].forEach.call(elems, function (el) {
        el.classList.remove('clicked-page')
      })
    }
  }, [dataLoaded])

  useEffect(() => {
    if (waypoints && waypoints.length > 0) {
      setState((s) => ({
        ...s,
        waypoints: waypoints,
      }))
      markersArr = []
      waypoints.forEach((wp) => {
        const lat = wp.start_location_latitude
        const long = wp.start_location_longitude
        markersArr.push({lat: parseFloat(lat), lng: parseFloat(long)})
      })
    }
  }, [waypoints])

  const setSelectedTrip = (trip) => {
    const {selected} = state
    let tmp = {...selected}

    const fields = ['start_location', 'destination']
    fields.forEach((fieldname) => {
      tmp[fieldname] = trip.attributes[fieldname]
      tmp[`${fieldname}_latitude`] = parseFloat(
        trip.attributes[`${fieldname}_latitude`],
      )
      tmp[`${fieldname}_longitude`] = parseFloat(
        trip.attributes[`${fieldname}_longitude`],
      )
    })

    setState((s) => ({
      ...s,
      selected: tmp,
      showTrip: true,
    }))
  }

  const unselectTrip = () => {
    setState((s) => ({
      ...s,
      selected: {},
      showTrip: false,
    }))
  }

  const updateDateFilters = async (fieldName, date) => {
    const {filters} = state
    if (!date) {
      filters[fieldName] = date
    } else {
      try {
        if (date < new Date()) {
          date = new Date()
        }
        filters[fieldName] =
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
      } catch {
        filters[fieldName] = date
      }
    }
    setState((s) => ({
      ...s,
      filters: {...filters},
    }))

    await resetDataLoadedRequest()
    await searchTripIdsRequest(state.filters)
  }

  const updateFilters = async (fieldName, event) => {
    const {filters} = state
    filters[fieldName] = event.target.value
    setState((s) => ({
      ...s,
      filters: {...filters},
    }))

    await resetDataLoadedRequest()
    await searchTripIdsRequest(state.filters)
  }

  const onMarkerClick = async (trip) => {
    const {filters} = state

    const {start_location_latitude, start_location_longitude, start_location} =
      trip

    filters.start_location = start_location
    filters.start_location_latitude = start_location_latitude
    filters.start_location_longitude = start_location_longitude

    setState((s) => ({
      ...s,
      filters: {...filters},
    }))

    await resetDataLoadedRequest()
    await searchTripIdsRequest(state.filters)
  }

  const updateSlider = async (fieldName, value) => {
    const {filters} = state
    if (value === 351) {
      delete filters[fieldName]
    } else {
      filters[fieldName] = value
    }

    setState((s) => ({
      ...s,
      filters: {...filters},
    }))

    await resetDataLoadedRequest()
    await searchTripIdsRequest(state.filters)
  }

  const changePrice = async (valArray) => {
    const {filters} = state
    filters.start_price = valArray[0]
    filters.end_price = valArray[1]
    setState((s) => ({
      ...s,
      filters: {...filters},
    }))

    await resetDataLoadedRequest()
    await searchTripIdsRequest(state.filters)
  }

  const setAddress = async (address, geometry, fieldName) => {
    const {filters, latitude, longitude, locationAvailable} = state

    filters[fieldName] = geometry ? address : undefined
    filters[`${fieldName}_latitude`] = geometry
      ? geometry.location.lat()
      : undefined
    filters[`${fieldName}_longitude`] = geometry
      ? geometry.location.lng()
      : undefined

    if (locationAvailable) {
      filters.latitude = latitude
      filters.longitude = longitude
    } else {
      filters.latitude = null
      filters.longitude = null
    }

    setState((s) => ({
      ...s,
      filters: {...filters, aaa: 'aaa'},
    }))

    await resetDataLoadedRequest()
    await searchTripIdsRequest(state.filters)
  }

  const handleExpandClick = () => {
    setState((s) => ({...s, expanded: !state.expanded}))
  }

  const createCard = (trip, trip_idx) => {
    // getTripInfoRequest(trip.id)
    return (
      <TripCard
        key={trip_idx}
        trip={JSON.parse(JSON.stringify(trip))}
        onMouseEnter={(trip_info) => setSelectedTrip(trip_info)}
        onMouseLeave={() => unselectTrip()}
        isSearch
      />
    )
  }

  const renderTrips = () => {
    if (trips.length > 0) {
      return trips.map((trip, index) => {
        return createCard(trip, index)
      })
    } else {
      return 'No Ridesurfers available near your location. Please try with filters.'
    }
  }

  const renderSimilarTrips = () => {
    return similarTrips.map((trip, index) => {
      return createCard(trip, index)
    })
  }

  const onPageNumClick = (e, page) => {
    const {filters} = state

    e.target.parentElement.classList.add('clicked-page')

    resetDataLoadedRequest()
    searchTripIdsRequest(filters, page)
  }

  const {filters, selected, showTrip, latitude, longitude} = state
  return (
    <div className="dashboard-container">
      <div className="formSection">
        <div className="container">
          <div className="row">
            <div className="col l2 m2 s12 singleSlider">
              <div className="label search-label">Journey</div>
            </div>
            <div className="col l5 m5 s12 singleSlider">
              <SearchField
                placeholder="Enter start location"
                value={filters.start_location || ''}
                setAddress={(address, geometry) =>
                  setAddress(address, geometry, 'start_location')
                }
              />
              <Slider
                defaultValue={351}
                min={0}
                max={351}
                value={filters.start_location_fence}
                renderThumb={() => <div>aaa</div>}
                onAfterChange={(value) =>
                  updateSlider('start_location_fence', value)
                }>
                <div className="slider-handle"></div>
              </Slider>
              <p className="slide-value">
                <span id="origin_fence_val">
                  {filters.start_location_fence === 351
                    ? ''
                    : filters.start_location_fence}
                </span>{' '}
                miles
              </p>
            </div>
            <div className="col l5 m5 s12 singleSlider">
              <SearchField
                placeholder="Enter destination"
                value={filters.destination || ''}
                setAddress={(address, geometry) =>
                  setAddress(address, geometry, 'destination')
                }
              />
              <Slider
                withBars
                defaultValue={351}
                min={0}
                max={351}
                value={
                  filters.destination_fence === 351
                    ? ''
                    : filters.destination_fence
                }
                onAfterChange={(value) =>
                  updateSlider('destination_fence', value)
                }>
                <div className="slider-handle"></div>
              </Slider>

              <p className="slide-value">
                <span id="destination_fence_val">
                  {filters.destination_fence}
                </span>{' '}
                miles
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col l2 m2 s12">
              <div className="label gender-label">Gender</div>
            </div>
            <div className="col l5 m5 s12">
              <FormControl className="selectField">
                <InputLabel htmlFor="select-multiple1"></InputLabel>
                <Select
                  value={filters.gender ? filters.gender : ''}
                  onChange={(event) => updateFilters('gender', event)}
                  input={<Input id="select-multiple1" />}
                  MenuProps={MenuProps}
                  displayEmpty
                  className="selected-menu-field">
                  {gender.map((data, index) => (
                    <MenuItem key={index} value={data[0]}>
                      {data[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col l5 m5 s12 mt20ml">
              <FormControl className="selectField">
                <InputLabel htmlFor="select-multiple">Events</InputLabel>
                <Select
                  value={filters.event_name || []}
                  onChange={(event) => updateFilters('event_name', event)}
                  input={<Input id="select-multiple" />}
                  placeholder="Events"
                  MenuProps={MenuProps}
                  multiple={true}
                  displayEmpty
                  className="selected-menu-field">
                  {eventNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{position: 'relative'}}>
            <div className="row">
              <div className="col l2 m2 s12">
                <div className="label date-range search-label">Date Range</div>
              </div>
              <div className="col l5 m5 s12">
                <DatePicker
                  selected={
                    filters.finish_date1 ? new Date(filters.finish_date1) : ''
                  }
                  onChange={(date) => updateDateFilters('finish_date1', date)}
                  minDate={new Date()}
                  maxDate={
                    filters.finish_date2
                      ? new Date(filters.finish_date2)
                      : addDays(new Date(), 1000)
                  }
                  placeholderText="Departure Date Range #1"
                  className={['text-field', styles.filterInput].join(' ')}
                />
              </div>
              <div className="col l5 m5 s12">
                <DatePicker
                  selected={
                    filters.finish_date2 ? new Date(filters.finish_date2) : ''
                  }
                  onChange={(date) => updateDateFilters('finish_date2', date)}
                  minDate={new Date()}
                  placeholderText="Departure Date Range #2"
                  className={['text-field', styles.filterInput].join(' ')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col l2 m2 s12">
              <div className="label search-label">Price</div>
            </div>
            <div className="col l10 m10 s12">
              <Slider
                withBars
                defaultValue={[0, 250]}
                min={0}
                max={250}
                onAfterChange={changePrice}
              />

              <span className="slide-value pull-left">
                ${filters.start_price}
              </span>
              <span className="slide-value pull-right">
                ${filters.end_price}
              </span>
            </div>
          </div>
          <div className="more-filter">
            <span onClick={handleExpandClick} aria-expanded={state.expanded}>
              {state.expanded ? (
                <Close className="close-icon" />
              ) : (
                <span className="show-filter">Show more filters</span>
              )}
            </span>
          </div>
          <Collapse in={state.expanded} timeout="auto" unmountOnExit>
            <div className="row">
              <div className="col l4 s12 mt20lg">
                <div className="filter-label">Kid Friendly:</div>
                <FormControl className="selectField">
                  <InputLabel htmlFor="select-multiple"></InputLabel>
                  <Select
                    value={filters.kid_friendly ? filters.kid_friendly : ''}
                    onChange={(event) => updateFilters('kid_friendly', event)}
                    input={<Input id="select-multiple" />}
                    MenuProps={MenuProps}
                    displayEmpty
                    className="selected-menu-field">
                    <MenuItem
                      value={filters.kid_friendly ? filters.kid_friendly : ''}
                      disabled>
                      Select
                    </MenuItem>
                    {basicFilters.map((data) => (
                      <MenuItem key={`kid-${data[0]}`} value={data[0]}>
                        {data[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col l4 s12 mt20lg">
                <div className="filter-label">Pets:</div>
                <FormControl className="selectField">
                  <InputLabel htmlFor="select-multiple"></InputLabel>
                  <Select
                    value={filters.pets ?? ''}
                    onChange={(event) => updateFilters('pets', event)}
                    input={<Input id="select-multiple" />}
                    MenuProps={MenuProps}
                    displayEmpty
                    className="selected-menu-field">
                    <MenuItem value={filters.pets ?? ''} disabled>
                      Select
                    </MenuItem>
                    {basicFilters.map((data) => (
                      <MenuItem key={`pet-${data[0]}`} value={data[0]}>
                        {data[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col l4 s12">
                <div className="filter-label">Smoking:</div>
                <FormControl className="selectField">
                  <InputLabel htmlFor="select-multiple"></InputLabel>
                  <Select
                    value={filters.smoking ?? ''}
                    onChange={(event) => updateFilters('smoking', event)}
                    input={<Input id="select-multiple" />}
                    MenuProps={MenuProps}
                    displayEmpty
                    className="selected-menu-field">
                    <MenuItem value={filters.smoking ?? ''} disabled>
                      Select
                    </MenuItem>
                    {basicFilters.map((data) => (
                      <MenuItem key={`smoke-${data[0]}`} value={data[0]}>
                        {data[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col l4 s12">
                <div className="filter-label">Car AC:</div>
                <FormControl className="selectField">
                  <InputLabel htmlFor="select-multiple"></InputLabel>
                  <Select
                    value={filters.car_ac ?? ''}
                    onChange={(event) => updateFilters('car_ac', event)}
                    input={<Input id="select-multiple" />}
                    MenuProps={MenuProps}
                    displayEmpty
                    className="selected-menu-field">
                    <MenuItem value={filters.car_ac ?? ''} disabled>
                      Select
                    </MenuItem>
                    {basicFilters.map((data) => (
                      <MenuItem key={`carAc-${data[0]}`} value={data[0]}>
                        {data[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col l4 s12">
                <div className="filter-label">Drive Type:</div>
                <FormControl className="selectField">
                  <InputLabel htmlFor="select-multiple"></InputLabel>
                  <Select
                    value={filters.drive_type ?? ''}
                    onChange={(event) => updateFilters('drive_type', event)}
                    input={<Input id="select-multiple" />}
                    MenuProps={MenuProps}
                    displayEmpty
                    className="selected-menu-field">
                    <MenuItem value={filters.drive_type ?? ''} disabled>
                      Select
                    </MenuItem>
                    {driveType.map((data) => (
                      <MenuItem key={data[0]} value={data[0]}>
                        {data[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Collapse>

          {!firstLoading && (
            <div>
              <Pagination
                current_page={pagination.current_page}
                total_pages={pagination.total_pages}
                onPageNumClick={onPageNumClick}
              />
            </div>
          )}

          {dataLoaded ? (
            <div>
              <div className="my-trips">
                <div
                  className="trips-container"
                  style={{
                    marginTop: -39,
                    justifyContent: 'flex-start',
                    marginLeft: 0,
                    marginRight: 0,
                  }}>
                  {renderTrips()}
                </div>
              </div>
              {similarTrips.length > 0 && (
                <div>
                  <h4>Similar Trips</h4>
                  <div className="trips-container">{renderSimilarTrips()}</div>
                </div>
              )}
            </div>
          ) : (
            <CircularLoading noPadding />
          )}
        </div>
      </div>
      <div className="mapSection">
        <GMap
          defaultLat={latitude}
          defaultLng={longitude}
          waypoints={waypoints}
          showTrip={showTrip}
          start_location={selected.start_location}
          start_location_latitude={selected.start_location_latitude}
          start_location_longitude={selected.start_location_longitude}
          destination={selected.destination}
          destination_latitude={selected.destination_latitude}
          destination_longitude={selected.destination_longitude}
          onMarkerClick={onMarkerClick}
        />
      </div>
    </div>
  )
}

export default memo(Dashboard)
